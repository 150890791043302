<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-orange h1 mb-3">Odoo For Marketing</h2>
      <p class="desc my-3">
        It makes managing the campaigns and Electronic mail Marketing quite easy
      </p>
      <ul class="desc my-3">
        <li>
          <p>Generates tremendous campaigns with the help of in-built tools</p>
        </li>
        <li>
          <p>Section your data for the specified mailers</p>
        </li>
        <li>
          <p>
            Design E-shots as well as Newsletters without any prior IT knowledge
          </p>
        </li>
        <li>
          <p>Process real-time feedback directly towards the system</p>
        </li>
      </ul>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/software-management/odoo-erp/odoo-for-marketing.png"
        alt="Odoo For Marketing"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "seventh-odoo-section",
};
</script>
