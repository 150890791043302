<template>
  <RightSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-yellow h1 mb-3">Odoo For Purchasing</h2>
      <p class="desc my-3">
        Enhance overall performance of your inventory and supply chain as well
        as manage your purchaser and supplier orders without any difficulty
      </p>
      <ul class="desc my-3">
        <li>
          <p>
            Systematize your purchasing work process by transmitting RFQs to
            sellers on the basis of stock levels
          </p>
        </li>
        <li>
          <p>Seller price lists & availability of a product</p>
        </li>
        <li>
          <p>Keep track of Orders (draft POs, RFQs, delivery dates, orders)</p>
        </li>
        <li>
          <p>Manage internal activities</p>
        </li>
        <li>
          <p>Deal with product variants</p>
        </li>
        <li>
          <p>Supervise future orders</p>
        </li>
        <li>
          <p>Monitor locations and stock</p>
        </li>
      </ul>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/software-management/odoo-erp/odoo-for-purchansing-min.png"
        alt="Odoo For Manufacturing"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "fourth-odoo-section",
};
</script>
