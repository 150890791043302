<template>
  <RightSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-yellow h1 mb-3">Odoo For Sales</h2>
      <p class="desc my-3">
        A user-friendly unified quotation device for proving orders and rapid
        quotes setback with only limited clicks
      </p>
      <ul class="desc my-3">
        <li>
          <p>
            Professional offers with the help of quote builder as well as price
            lists and products combined in a single form
          </p>
        </li>
        <li>
          <p>
            E-mail quote straight through the system and acquire e-signature
            agreement
          </p>
        </li>
        <li>
          <p>Manage Contracts and Orders</p>
        </li>
        <li>
          <p>
            Minimize data entry – transform the quote to invoice and order from
            a similar screen
          </p>
        </li>
      </ul>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/software-management/odoo-erp/odoo-sales.png"
        alt="Odoo For Sales"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "sixth-odoo-section",
};
</script>
