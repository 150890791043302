<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-orange h1 mb-3">Odoo ERP</h2>
      <p class="desc my-3">
        Odoo is a public domain ERP software suite which comprises of numerous
        modules like CRM, Sales, Project as well as Warehouse management,
        Manpower Assets, Accounting, and Manufacturing, etc.
      </p>
      <p class="desc my-3">
        That particular system that manages an entire business system by
        combining all of the enterprise systems into one is referred to as
        Enterprise Resource Planning (ERP). All the organization’s sectors are
        interrelated and exchange their information together. Several events of
        business for instance keeping track of the order, managing the
        inventory, customer service, finance as well as manpower assets can be
        automated procedures through workflows.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/software-management/odoo-erp/odooimg-1a.png"
        alt="Odoo ERP"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "first-odoo-section",
};
</script>
