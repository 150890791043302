<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-orange h1 mb-3">Odoo For CRM</h2>
      <p class="desc my-3">
        With the help of a fully incorporated solo solution, provide client
        relationship management (CRM) to real customer-centric
      </p>
      <ul class="desc my-3">
        <li>
          <p>
            The single truth alongside all of your communications with your
            customers, sellers, and prospects for your teams
          </p>
        </li>
        <li>
          <p>Tracking Leads and securing more chances</p>
        </li>
        <li>
          <p>
            Efficiency tools in order to manage meetups, calls, tasks, e-mails,
            etc.
          </p>
        </li>
        <li>
          <p>Simultaneous reporting with Dashboard as well as analysis</p>
        </li>
      </ul>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/software-management/odoo-erp/odoo-crm-min21.png"
        alt="Odoo ERP"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "fiveth-odoo-section",
};
</script>
