<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-orange h1 mb-3">Odoo For Helpdesk</h2>
      <p class="desc my-3">
        Efficiency gears for outstanding client service in order to solve, rank
        and keep track of client problems
      </p>
      <ul class="desc my-3">
        <li>
          <p>
            A system for dealing with tickets with user-friendly Kanban view
            keep track of issues
          </p>
        </li>
        <li>
          <p>Generate and set Service Level Agreement (SLA) rules</p>
        </li>
        <li>
          <p>
            Systematize electronic mails & actions to accelerate resolutions
          </p>
        </li>
        <li>
          <p>Increase the extent of tickets to line administrators</p>
        </li>
        <li>
          <p>Call for experts to live incorporated debates</p>
        </li>
        <li>
          <p>Accomplish Support contracts</p>
        </li>
        <li>
          <p>Deal with timesheets</p>
        </li>
        <li>
          <p>
            Deliver a wiser self-service through the integral client knowledge
            base
          </p>
        </li>
      </ul>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/software-management/odoo-erp/odoo-for-help-desk.png"
        alt="Odoo For Helpdesk"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "nineth-odoo-section",
};
</script>
