<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-orange h1 mb-3">Why Ejaftech ?</h2>
      <p class="desc my-3">
        We are determined to practice the optimum Odoo implementation techniques
        as we are a <strong>Certified Odoo Gold partner</strong>, we are
        committed to follow Odoo implementation best practices. By working
        amongst us, you will see that we:
      </p>
      <ul class="desc my-3">
        <li>
          <p>
            Assist you in analyzing and examining rapid business success that
            will provide your business with the swiftest returns.
          </p>
        </li>
        <li>
          <p>
            Select the finest and most inexpensive tools that will enhance your
            throughput.
          </p>
        </li>
        <li>
          <p>
            Keep the duration of implementation short and cheap for the outcomes
            to be perceived quickly.
          </p>
        </li>
        <li>
          <p>
            Organize strategic analyses for your future plans and growth – i.e.
            Focus on your vision and goals with systems and tools required by
            your business.
          </p>
        </li>
      </ul>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/software-management/odoo-erp/why-ejaf-odoo.png"
        alt="Why Ejaftech ?"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "eleventh-odoo-section",
};
</script>
