<template>
  <odoo-erp />
</template>

<script>
import OdooErp from "../../components/solutions/software-management/OdooErp.vue";
export default {
  components: { OdooErp },
};
</script>
