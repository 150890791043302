<template>
  <RightSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-yellow h1 mb-3">Odoo For Finance</h2>
      <p class="desc my-3">
        Accounting Software program with efficiency gears for the 21<sup
          >st</sup
        >
        Century
      </p>
      <ul class="desc my-3">
        <li>
          <p>Receivable Accounts</p>
        </li>
        <li>
          <p>Payable Accounts</p>
        </li>
        <li>
          <p>Cash and Bank Management</p>
        </li>
        <li>
          <p>Multi-Currency and Multi-Company</p>
        </li>
        <li>
          <p>
            Legal Announcements (P&L, Tax Audit Reports, Cash Flow, Balance
            Sheets, General Ledger, etc.)
          </p>
        </li>
        <li>
          <p>Analytical Accounting</p>
        </li>
        <li>
          <p>Presentation Reports</p>
        </li>
      </ul>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/software-management/odoo-erp/odoo-for-finance-min.png"
        alt="Odoo For Finance"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "eightth-odoo-section",
};
</script>
