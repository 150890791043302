<template>
  <RightSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-yellow h1 mb-3">Odoo For Human Resources</h2>
      <p class="desc my-3">
        The software will improve the efficiency of the HR team, and helps the
        organization successfully manages and centralizes all HR information.
      </p>
      <ul class="desc my-3">
        <li>
          <p>Hiring, Recruiting, and Retention</p>
        </li>
        <li>
          <p>Track time and attendance</p>
        </li>
        <li>
          <p>Handling employee concerns</p>
        </li>
        <li>
          <p>Employee benefits</p>
        </li>
        <li>
          <p>Compensation</p>
        </li>
        <li>
          <p>Employee Performance evaluation</p>
        </li>
        <li>
          <p>Training and development</p>
        </li>
        <li>
          <p>Employee records and performance reviews</p>
        </li>
        <li>
          <p>Employee incentives</p>
        </li>
        <li>
          <p>Compliance</p>
        </li>
        <li>
          <p>Termination</p>
        </li>
      </ul>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/software-management/odoo-erp/odoo-for-human-resources-min.png"
        alt="Odoo For Human Resources"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "tenth-odoo-section",
};
</script>
