<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-orange h1 mb-3">Odoo For Inventory Management</h2>
      <p class="desc my-3">
        With the help of a modern warehouse management solution, improve the
        productivity of your warehouse
      </p>
      <ul class="desc my-3">
        <li>
          <p>
            Full inventory management and control (Internal transfers, Pack,
            Receipts, Pick, Manufacturing, Delivery Orders)
          </p>
        </li>
        <li>
          <p>Dual Entry Smart Inventory System</p>
        </li>
        <li>
          <p>Entirely Automated Replacement</p>
        </li>
        <li>
          <p>Traceability</p>
        </li>
        <li>
          <p>Drop-Shipping</p>
        </li>
        <li>
          <p>Multi Warehouse</p>
        </li>
        <li>
          <p>Cross Docking</p>
        </li>
      </ul>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/software-management/odoo-erp/odoo-for-inventory-min.png"
        alt="Odoo ERP"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "third-odoo-section",
};
</script>
