<template>
  <RightSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-yellow h1 mb-3">Odoo For Manufacturing</h2>
      <p class="desc my-3">
        A multipurpose solution with MRP, Preservation, Excellence, and an
        entire PLM incorporated a system to deliver an advanced solution to an
        old issue.
      </p>
      <ul class="desc my-3">
        <li>
          <p>Manufacturing Engineering (Worksheets, Routings)</p>
        </li>
        <li>
          <p>Manufacturing (Works Orders, Control Panel, Planning)</p>
        </li>
        <li>
          <p>Quality (Alerts, Checks – SPC, Control Points)</p>
        </li>
        <li>
          <p>Engineering (Versioning, Bill of Materials, PLM)</p>
        </li>
        <li>
          <p>Maintenance (Maintenance Requests, Equipment Management)</p>
        </li>
        <li>
          <p>Supply Chain (Procurement Rules, Routes, MPS)</p>
        </li>
      </ul>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/software-management/odoo-erp/odooimg-1.png"
        alt="Odoo For Manufacturing"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "second-odoo-section",
};
</script>
